<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Operational Reports Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      tableData: [],
      templateArr: [],
      restBranchArr: [],
      restBranchID: "",
      templateID: "",
      branches: [],
      princBranches: [],
      branchID: "",
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      title: "Operational Reports Table",
      items: [
        {
          text: "View",
        },
        {
          text: "Operational Reports Table",
          active: true,
        },
      ],
      corpBranchID: "",
      principleBranchID: "",
      restaurantBranchID: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",

          sortable: false,
        },
        {
          key: "restaurant",
          sortable: true,
        },
        {
          key:
            this.$storageData.login_type == 3 ||
            this.$storageData.login_type == 4
              ? "operationalChecklist"
              : "templateTitle",
          sortable: true,
        },
        {
          key: "totalAchieved",
          sortable: true,
        },
        {
          key: "totalSelfScore",
          sortable: true,
        },
        // {
        //   key:"createdBy",
        //   sortable:true,
        // },
        {
          key: "created",
          sortable: true,
        },
      ],
    };
  },

  created() {
    if (this.$storageData.login_type == 3) {
      this.corpBranchID =
        this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5
          ? this.brandLogin()
          : this.getStats();
    }

    if (this.$storageData.login_type == 4) {
      this.principleBranchID =
        this.$storageData.profile.empTypeID == 8
          ? this.principleBranchLogin()
          : this.getPrincipleCountry();
    }
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    if (this.$storageData.profile.empTypeID == 9) {
      this.getCorporateCities();
    }

    // this.readComplianceReportData(this.corpBranchID);
  },

  methods: {
    /**
     * Search the table data with search input
     */
    brandLogin() {
      this.getCorporateCountry();
    },

    getPrincipleCountry() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCountry", {
          principleID: this.$storageData.profile.principleID,
        })
        .then((response) => {
          this.countryArr = response.data.data;
        });
    },

    getPrincipleCities() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCities", {
          principleID: this.$storageData.profile.principleID,
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
        })
        .then((response) => {
          this.cityArr = response.data.data;
        });
    },

    getPrincipleBranchByPrincipleID() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
          principleID: this.$storageData.profile.principleID,
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
        })
        .then((response) => {
          this.princBranches = response.data.data;
          // this.branchID = this.princBranches[0];
          this.principleBranchID = this.branchID.principleBranchID;
          // this.readComplianceReportDataForPrinciple();
        });
    },

    getCorporateCountry() {
      this.axios
        .post(this.$loggedRole+"/getCorporateCountry", {
          corporateID: this.$storageData.profile.corporateID,
        })
        .then((response) => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getCorporateCities();
        });
    },

    getCorporateCities() {
      if (this.$storageData.profile.city != "" && this.$storageData.profile.city != undefined) {
        this.cityArr = [{ city: this.$storageData.profile.city }];
        this.city = this.cityArr ? this.cityArr[0] : "";
        this.getCorporateBranchByCorpID();
      } else {
        this.axios
          .post(this.$loggedRole+"/getCorporateCities", {
            corporateID: this.$storageData.profile.corporateID,
            country: this.country ? this.country.country : "",
          })
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            this.getCorporateBranchByCorpID();
          });
      }
    },

    getCorporateBranchByCorpID() {
      this.axios
        .post(this.$loggedRole+"/getCorporateBranchByCorpID", {
          corporateID: this.$storageData.profile.corporateID,
          city: this.city ? this.city.city : "",
        })
        .then((response) => {
          this.branches = response.data.data;
          this.branchID = this.branches[0];
          this.corpBranchID = this.branchID.corpBranchID;
          this.readComplianceReportData();
        });
    },

    onchangeBranch() {
      if (this.$storageData.login_type == 4) {
        this.principleBranchID = this.branchID.principleBranchID;
        // this.readComplianceReportDataForPrinciple();
      } else {
        this.corpBranchID = this.branchID.corpBranchID;
        this.readComplianceReportData();
      }
    },

    onchangeRestBranch() {
      this.restaurantBranchID = this.restBranchID.restBranchID;
      this.readComplianceReportData();
    },

    getStats() {
      this.corpBranchID = this.$storageData.profile.corpBranchID;
      this.readComplianceReportData();
    },

    //// principle dashboard  methods///////
    principleBranchLogin() {
      this.principleBranchID = this.$storageData.profile.principleBranchID;
       this.readComplianceReportDataForPrinciple();
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readComplianceReportData() {
      this.axios
        .post(this.$loggedRole+"/viewAllComplianceReportsForCorporate", {
          corpBranchID: this.corpBranchID,
          restBranchID: this.restaurantBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          this.restBranchArr = response.data.restaurantArr;
          //this.restBranchID = (this.restBranchArr) ? this.restBranchArr[0] : "";
          //console.log((response));
        });
    },

    readComplianceReportDataForPrinciple() {
      // princ dashboard
      var principleID =
        this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0
          ? this.$storageData.profile.principleID
          : 0;
      var principleBranchID =
        this.$storageData.profile.principleBranchID &&
        this.$storageData.profile.principleBranchID != 0
          ? this.$storageData.profile.principleBranchID
          : this.principleBranchID != ""
          ? this.principleBranchID
          : 0;

      this.axios
        .post(this.$loggedRole+"/viewAllComplianceReportsForPrinciple", {
          // 'principleBranchID':this.principleBranchID,
          principleID: principleID,
          principleBranchID: principleBranchID,
          restBranchID: this.restaurantBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          city: this.city ? this.city.city : "",
          country: this.country ? this.country.country : "",
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          this.restBranchArr = response.data.restaurantArr;
          //this.restBranchID = (this.restBranchArr) ? this.restBranchArr[0] : "";
          //console.log((response));
        });
    },

    applyFilter() {
      if (
        this.$storageData.login_type == 3 ||
        this.$storageData.profile.empTypeID == 5
      ) {
        this.readComplianceReportData();
      } else {
        this.principleBranchID =
          this.$storageData.login_type == 4 ||
          this.$storageData.profile.empTypeID == 7 ||
          this.$storageData.profile.empTypeID == 18
            ? this.branchID.principleBranchID
            : this.$storageData.profile.principleBranchID;
        this.readComplianceReportDataForPrinciple();
      }
    },

    clearFilter() {
      this.typeID = 0;
      this.country = "";
      this.city = "";
      this.branchID = "";
      this.dateFilter = [];
      this.principleBranchID =
        this.$storageData.login_type == 4 ||
        this.$storageData.profile.empTypeID == 7 ||
        this.$storageData.profile.empTypeID == 18
          ? this.branchID.principleBranchID
          : this.$storageData.profile.principleBranchID;
      this.tableData=""
      this.restBranchArr="";
    },
  },
  
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />

    <!--FILTER START--->
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- corp login filters -->
          <div
            v-if="
              this.$storageData.login_type == 3 ||
                this.$storageData.profile.empTypeID == 5
            "
            class="col-md-2"
            style="width:16%"
          >
            <label>Select Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getCorporateCities()"
            ></multiselect>
          </div>
          <div
            v-if="
              this.$storageData.login_type == 3 ||
                this.$storageData.profile.empTypeID == 5 ||
                this.$storageData.profile.empTypeID == 9
            "
            class="col-md-2"
            style="width:16%"
          >
            <label>Select City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="getCorporateBranchByCorpID()"
            ></multiselect>
          </div>
          <div
            v-if="
              this.$storageData.login_type == 3 ||
                this.$storageData.profile.empTypeID == 5 ||
                this.$storageData.profile.empTypeID == 9
            "
            class="col-md-3"
            style="width:16%"
          >
            <label>Select Corporate Branch* </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              :show-labels="false"
              label="corpBranchName"
              track-by="corpBranchID"
              @input="onchangeBranch()"
            ></multiselect>
          </div>

          <!-- corp owner login -->

          <!-- Brand List-->
          <div
            v-if="
              this.$storageData.login_type == 4 ||
                this.$storageData.profile.empTypeID == 7 ||
                this.$storageData.profile.empTypeID == 18
            "
            class="col-md-2"
            style="width:16%"
          >
            <label>Select Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getPrincipleCities()"
            ></multiselect>
          </div>
          <!-- Brand List END-->
          <!-- Branch List-->
          <div
            v-if="
              this.$storageData.login_type == 4 ||
                this.$storageData.profile.empTypeID == 7 ||
                this.$storageData.profile.empTypeID == 18
            "
            class="col-md-2"
            style="width:16%"
          >
            <label>Select City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="getPrincipleBranchByPrincipleID()"
            ></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- Branch List-->
          <div
            v-if="
              this.$storageData.login_type == 4 ||
                this.$storageData.profile.empTypeID == 7 ||
                this.$storageData.profile.empTypeID == 18
            "
            class="col-md-2"
            style="width:20%"
          >
            <label>Select Principal Branch </label>
            <multiselect
              v-model="branchID"
              :options="princBranches"
              :show-labels="false"
              label="principleBranchName"
              track-by="principleBranchID"
            ></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- Date Range-->
          <!-- Branch List END-->

          <!-- Apply filter -->
          <div class="col-md-2" style="width:auto; margin-top:30px;">
            <button class="btn btn-themeOrange" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <div class="col-md-2" style="width:16%;margin-top:30px;">
            <button class="btn btn-themeBrown" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>
    <!-- <div class="col-md-2" style="position: absolute;right: 44%;top: 75px;"
              v-if="this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 ">

                          <label>Select Country </label>
                              <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                                @input="getPrincipleCities();" :show-labels="false"></multiselect>
          </div>
      <div class="col-md-2" style="position: absolute;right: 29%;top: 75px; width: 15%;"
         v-if="this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 ">

                     <label>Select City </label>
                        <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                          @input="getPrincipleBranchByPrincipleID();" :show-labels="false"></multiselect>
      </div>
      <div class="col-md-3" style="position: absolute; width: 20%; right: 10%;top: 75px;" v-if="this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 ">
                <label>Select Principle Branch </label>
                <multiselect v-model="branchID" :options="princBranches" track-by="principleBranchID" label="principleBranchName"
                            @input="onchangeBranch();" :show-labels="false"></multiselect>
      </div> -->

    <!-- <div class="col-md-3" style="position: absolute;right: 11%;top: 84px;" 
        >   

                     <label>Select Restaurant Branch* </label>
                        <multiselect v-model="restBranchID" :options="restBranchArr" track-by="restBranchID" label="restaurantName"
                          @input="onchangeRestBranch();" :show-labels="false"></multiselect>
      </div> -->
    <div class="row" style="margin-top:30px;">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                <div class="btn-group" role="group">
                  <!-- <button type="button" class="btn btn-themeBrown">Excel</button> 
                       <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      style="margin-left:5px; margin-right:5px"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      class="form-control form-control-sm ms-2"
                      placeholder="Search..."
                      type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :current-page="currentPage"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="tableData"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                hover
                outlined
                responsive
                striped
                @filtered="onFiltered"
              >
                <template v-slot:cell(Action)="data">
                  <router-link
                    v-if="data.item.isEdit == 1"
                    :to="{
                      name: 'operationalReportForm',
                      params: {
                        id: data.item.complianceTemplateID,
                        type: 'edit',
                      },
                    }"
                  >
                    <button
                      class="btn btn-themeBrown"
                      style="padding: 1px 9px;"
                    >
                      View
                    </button>
                  </router-link>

                  <!-- <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i> -->
                </template>

                <template v-slot:cell(totalAchieved)="data">
                  {{ data.item.totalAchieved }} %
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>